@import '../../../styles/constants.scss';

.pagination-component {
    width: 100%;
    padding: 3px 24px 16px 0px;
    border-top: 1px solid $light-black;
}

.Mui-disabled {
    background-color: $shadow-light !important;
    border: 0px !important;
    color: $overlay-light !important;
    width: 30px;
    height: 28px;
    margin: 2px !important;
}
  