.main-section {
  padding: 20px 15px 10px 15px;
  height: calc(100vh - 100px);
  overflow: hidden auto;
}

.global-filter-heading {
  font-size: 16px;
  color: #333843;
  font-weight: 700;
  font-family: Inter, sans-serif;
  letter-spacing: 0.5px;
}

.global-filter-section {
  display: flex;
  justify-content: space-between;
}

.close {
  width: 20px;
  height: 20px;
  opacity: 1;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  right: 25px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #323232;
  cursor: pointer;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.new-filter-heading {
  margin-top: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: center;
  height: 35px;
  border-radius: 6px;
  padding: 4px 5px 3px 5px;
}

.MuiSvgIcon-root.plus-icon {
  font-size: 20px;
  position: relative;
  top: 5px;
  right: 8px;
}

.no-filter-created {
  margin-top: 25%;
  margin-left: 10px;
}

.global-filter-btn-section {
  padding: 10px;
  margin-top: 10px;
}

.clear-all-btn-section {
  text-align: center;
  margin-top: 15px;
}

.filter-checkbox-section {
  display: inline-block;
  width: 75%;
}

.global-filter-name {
   margin-right: 10px;
}

.filter-name {
  font-size: 14px;
  color: #667085;
  font-weight: 500;
  font-family: Inter, sans-serif;
  letter-spacing: 0.5px;
  margin-left: 3px;
}

.data-source-name {
  display: block;
  margin-top: 10px;
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
}

.MuiCheckbox-root.filter-checkbox {
  padding: 0px;
  margin-left: 3px;
  position: relative;
  top: 5px;
}

.MuiSvgIcon-root.global-filter-icon {
  cursor: pointer;
}

.MuiSvgIcon-root.edit-icon {
  margin-right: 5px;
}

.global-filter-truncate {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 10px;
  left: 3px;
  width: 80%;
}

.add-global-filter-container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 15px;
  padding: 3px 5px 10px 5px;
}

.filter-icons {
  margin-top: 5px;
}