.table-panel-container {
  height: 90%;
  padding: 0px 20px 0px 20px;
  margin-top: 5px;
}

.MuiPaper-root.MuiTableContainer-root {
  height: 90%;
}

.data-not-found-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.data-not-found-msg {
  text-align: center;
}

.MuiTableCell-root.data-cell {
  text-align: center;
  border-bottom: none;
}
