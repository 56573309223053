.image-config-heading {
   margin-bottom: 25px;
}

.file-upload-container {
  margin-top: 30px;
}

.drop-zone {
  text-align: center;
  padding: 30px;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file {
  display: inline-block;
  position: relative;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.image-info-conatiner {
  margin-top: 40px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.image-widget-size-info {
  margin-top: 10px;
}

.MuiSvgIcon-root.image-widget-icon {
  color: #3648c7;
  cursor: pointer;
}

.MuiSvgIcon-root.upload-icon {
  font-size: 35px;
}

.uploaded-image {
  height: 30px;
  width: 30px;
}

.character-limit-msg {
  position: relative;
  left: 15px;
}