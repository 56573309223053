// Apps Container CSS started
.apps-ContentWrapper {
  display: flex;
  flex-direction: column;
  background-color: #f9f9fc;
  height: 100%;
  margin-bottom: 50px;
}

.no-app-list {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  height: 68vh;
}

.MuiButton-root.genericApps-btn {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 10px 14px 12px 14px;
  border-radius: 8px;
  box-shadow: unset;
}

.MuiGrid-item.genericApps-gridItem {
  padding-left: 10px !important;
}

.MuiGrid-item.genericApps-gridRightItem {
  padding-right: 15px !important;
}

.MuiGrid-item.appgrid-item {
  padding-left: 0px !important;
}

.MuiInputLabel-root.apps-label {
  margin-bottom: 3px;
}

.data-source-label {
  margin-bottom: 3px;
}

.displayFlex {
  display: flex !important;
}

// Apps Header CSS Started

.appsHeader-container {
  border-bottom: 1px solid #e0e2e7;
  height: 60px;
  justify-content: space-between;
  padding: 10px 20px 15px 20px;
}

.red-asterisk {
  color: red;
  font-size: 12px;
}

.MuiTypography-root.appsHeader-subTitleName {
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.12px;
  color: #4d5464;
  max-width: 20rem;
}

.userBtn-section {
  .MuiStack-root {
    float: right;
    margin-top: 5px;
  }
}

.MuiButton-root.apps-userBtn {
  padding: 5px 15px 5px 10px;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #3648c7;
  font-family: Inter, sans-serif;
  box-shadow: unset !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.MuiButton-root.apps-NewAppBtn {
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  height: 40px;
  background-color: #3648c7;
  color: #ffffff;
  position: relative;
  bottom: 5px;
}

.MuiButton-root.apps-NewAppBtn:hover {
  box-shadow: unset;
  background-color: #3648c7;
}

.MuiButton-root.apps-open-app-btn {
  padding: 3px 5px 3px 5px;
  justify-content: center;
  gap: 4px;
  border-radius: 3px;
  background-color: #3648c7;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-right: 3px;
  position: relative;
  text-transform: unset;
  box-shadow: unset;
  min-width: unset;
}

.MuiButton-root.apps-open-app-btn :hover {
  padding: 0px 7px;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  background-color: #3648c7;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  position: relative;
  text-transform: unset;
}

.MuiButton-root.apps-edit-btn {
  min-width: 0;
}

// Add New App CSS Started
.MuiDialogContent-root.dialog-mainSection {
  padding: 3% 7% 3% 7%;
  .MuiFormLabel-asterisk {
    color: red !important;
  }
}

.dialogBody-grid {
  position: relative;
  left: 4px;
}

.new-app-title {
  color: #4d5464;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.09px;
}

.label-name {
   margin-bottom: 5px;
}

.MuiGrid-item.grid-section {
  margin-top: 15px;
}

.MuiOutlinedInput-input.description-input {
  background-color: #f9f9fc !important;
}

.description-input .MuiOutlinedInput-input {
  height: 70px;
}

.MuiGrid-item.app-config-section {
  border-right: 1px solid #e0e2e7;
}

.MuiGrid-item.dashboard-template-grid {
  padding-right: 16px;
}

.MuiGrid-root.app-config-container {
  border-bottom: 1px solid #e0e2e7;
}

.create-new-img-container {
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
}

.cancel-btn-section {
  width: 49%;
  text-align: left;
  display: inline-block;
}

.save-btn-section {
  width: 49%;
  text-align: right;
  display: inline-block;
}

.grid-btn {
  width: 100%;
  position: relative;
  bottom: 10px;
  top: 5px;
}

.grid-edit-btn {
  width: 100%;
  position: relative;
  bottom: 5px;
  top: 5px;
}

.appName-errorSection {
  font-size: 12px;
  font-style: normal;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: red;
  margin-left: 10px;
}

.dashboardName-errorSection {
  font-size: 12px;
  font-style: normal;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: red;
  margin-left: 60px;
}

.MuiButton-root.save-btn {
  color: #fff;
  border-radius: 8px;
  background-color: #3648c7;
  margin-right: 25px;
  position: relative;
  top: 3px;

  &:hover {
    background-color: #3648c7;
    color: #fff;
    box-shadow: unset;
  }
}

.MuiButton-root.disabledSave-btn {
  pointer-events: none;
  color: #fff;
  border-radius: 8px;
  background-color: #808080cc;
  position: relative;
  top: 3px;
  margin-right: 25px;
}

.MuiButton-root.cancel-btn {
  border-radius: 8px;
  color: #3648c7;
  border: 1px solid #3648c7;
  margin-left: 20px;
}

.apps-pagination {
  width: 100% !important;
  background-color: #f5f6f7;
  height: 40px;
  position: fixed;
  bottom: 0px !important;
  display: flex;
  align-items: center;
}

.editedText-section {
  display: inline-block;
  text-align: left;
  width: 50%;
  padding-left: 2px;
}

.actionBtn-section {
  display: flex;
}

.MuiTypography-root.apps-PaginationText {
  margin-left: 20px;
  width: 275px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Inter, sans-serif;
  letter-spacing: 0.07px;
}

.page-size-selection {
  margin-left: 20px;
  color: #667085;
}

.MuiFormControlLabel-label.public-private-btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5%;
  color: #667085;
}

.MuiPaper-root-MuiDialog-paper {
  color: #4d5464;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-bottom: 5px;
}

.MuiGrid-root.appsHeader-tabsContainer {
  width: 100%;
  margin-left: 0px;
  margin-top: 0px;
}

.appsHeader-searchSection {
  display: inline-block;
  text-align: end;
  position: relative;
  top: -15px;
}

.MuiTab-root.appsHeader-favroiteTab {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin-left: 20px;
  color: #667085 !important;
}

.MuiTextField-root.appsHeader-search {
  margin-right: 32px;

  .MuiOutlinedInput-root {
    width: 250px;
    height: 42px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.07px;
    border-radius: 8px;
    background-color: #ffffff;
  }
}

.appsHeader-menucontainer {
  display: inline-block;
}

.MuiButton-root.appsheader-filter {
  padding: 10px 16px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
}

.addDashboardIcon {
  padding-left: 20px;
}

.data-source-chip {
  max-height: 100px;
  overflow: auto;
  border-radius: 8px;
  padding: 10px;
}

.MuiChip-root.chip {
  border-radius: 4px !important;
  padding: 4px 8px;
  margin: 5px;

  .MuiChip-label {
    color: #333843;
  }
}

.MuiBackdrop-root.MuiModal-backdrop.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  box-shadow: unset !important;
}

@media only screen and (max-width: 1068px) and (min-width: 768px) {
  .MuiButton-root.apps-NewAppBtn {
    display: none;
  }

  .appsHeader-menucontainer {
    display: none;
  }

  .MuiGrid-root.appsHeader-tabsContainer {
    width: 100%;
    margin-left: 10px;
  }

  .MuiTextField-root.appsHeader-search {
    margin-right: 25px;

    .MuiOutlinedInput-root {
      position: relative;
      width: 150px;
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 350px) {
  .MuiTextField-root.appsHeader-search {
    margin-right: 15px;

    .MuiOutlinedInput-root {
      position: relative;
      width: 120px;
    }
  }

  .MuiButton-root.apps-NewAppBtn {
    display: none;
  }

  .appsHeader-menucontainer {
    display: none;
  }

  .MuiGrid-root.appsHeader-tabsContainer {
    width: 100%;
    margin-left: 10px;
  }

  .MuiTab-root.appsHeader-favroiteTab {
    font-size: 12px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .MuiButton-root.apps-NewAppBtn {
    display: none;
  }

  .appsHeader-menucontainer {
    display: none;
  }

  .MuiGrid-root.appsHeader-tabsContainer {
    width: 100%;
    margin-left: 10px;
  }

  .MuiTextField-root.appsHeader-search {
    margin-right: 25px;

    .MuiInputBase-root-MuiOutlinedInput-root {
      width: 50px;
      position: relative;
      top: 10px;
    }
  }

  .MuiTab-root.appsHeader-favroiteTab {
    font-size: 12px;
    padding-left: 0px;
  }
}
