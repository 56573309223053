.widget-config-header-component {
    height: 65px;
    border-bottom: 1px solid #e7e7e7;
    background-color: white;
}

.add-edit-new-chart {
    display: flex;
    align-items: center;
    padding-top: unset !important;
    padding-left: 45px !important;

}

.add-edit-new-chart-action-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: unset !important;
    padding-right: 15px !important;
}

.header-title {
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 24px;
    color: #333843;
    font-weight: 700;
    margin-left: 5px;
}