.process-model-variants-selection-panel {
  .selected-percent {
    margin-top: 10px;
  }

  .MuiButton-root.close-variants-btn {
    padding: 3px 7px 3px 7px;
    justify-content: center;
    gap: 4px;
    border-radius: 3px;
    color: #3648c7;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-right: 3px;
    position: relative;
    text-transform: unset;
    box-shadow: unset;
    min-width: unset;
    height: fit-content;
    border: 1px solid #3648c7;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
  }

  .actions-container {
    display: flex;
  }

  .chart-container {
    width: 400px;
    height: 500px;
  }
}