.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter, sans-serif;
  letter-spacing: 0.5px;
  width: 80%;
}

.dialog-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-as-table-pagination {
  width: 100% !important;
  height: 40px;
  position: unset;
  bottom: unset !important;
  display: flex;
  align-items: center;
}

.MuiTableCell-head {
  font-size: 18px !important;
  font-weight: 700;
}

.MuiTextField-root.widget-search-text-field {
  width: 300px;
}

.no-data-list {
  text-align: center;
}

.license-type {
  width: 300px;
}

.MuiGrid-root.search-export-container {
  margin-bottom: 15px;
  margin-top: 1px;
}

.MuiGrid-item.export-csv {
  text-align: right;
}
