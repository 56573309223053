.MuiTypography-root.process-model-chart-title {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #667085;
  font-family: Inter, sans-serif;
  margin-left: 20px;
}

.settings-container {
  text-align: right;
  margin-top: 20px;
}

.MuiButton-root.process-chart-btn {
  min-width: 40px;
  height: 34px;
  border: 1px solid #3648c7;
  background-color: #ffffff;
  margin-right: 15px;
}

.MuiButton-root.process-chart-btn:hover {
  box-shadow: unset;
  background-color: #ffffff;
}

.icon-color {
  color: #3648c7;
}

.percentage-text {
  color: 'red';
}

button.MuiButtonBase-root.MuiToggleButton-root.portrait-orientation-selected {
  color: #ffffff;
  background-color: #3648c7;
  border: 1px solid #3648c7;
}

button.MuiButtonBase-root.MuiToggleButton-root.portrait-orientation-not-selected {
  color: #3648c7;
  background-color: #ffffff;
  border: 1px solid #3648c7;
}

button.MuiButtonBase-root.MuiToggleButton-root.landscape-orientation-selected {
  color: #ffffff;
  background-color: #3648c7;
  border: 1px solid #3648c7;
  padding: 12px;
}

button.MuiButtonBase-root.MuiToggleButton-root.landscape-orientation-not-selected {
  color: #3648c7;
  background-color: #ffffff;
  border: 1px solid #3648c7;
  padding: 12px;
}

#zoom-value {
  padding: 7px 30px 7px 7px;
  border: 1px solid #3648c7;
}

.svg-container-view {
  position: absolute;
  height: 100%;
  width: 100%;
}

.svg-container-view-edit-mode {
  position: absolute;
  height: 100%;
  width: calc(100% - 33.73%);
}