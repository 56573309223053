.background-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  position: absolute;
  left: 0;
  margin-left: 19px;
  margin-top: 12px;
  top: 135px;
  bottom: 0;

}

.background-grid-column {
  background-color: #E8F8FD;
  border: solid 1px #E8F8FD;
}