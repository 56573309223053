.react-grid-layout.layout {
  transition: height 200ms ease !important;
}

.gridItemContent {
  position: relative;
  cursor: move;
}

.drag-handle {
  position: relative !important;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border: 1px solid #999;
  cursor: move;
}

.backgroundGrid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(to right, rgba(255, 192, 203, 0.6) 10px, transparent 10px),
    linear-gradient(90deg, rgba(255, 192, 203, 0.6) 10px, transparent 10px),
    linear-gradient(to right, white 20px, transparent 20px),
    linear-gradient(90deg, white 20px, transparent 20px);
  background-size:
    100px 100px,
    100px 100px,
    20px 20px,
    20px 20px;
  background-position:
    0 0,
    0 0,
    0 0,
    0 0;
}

.gridContainer {
  position: relative;
}
.react-grid-item,
.react-grid-item.cssTransforms,
.react-grid-item.react-draggable-dragging {
  z-index: auto !important;
  will-change: auto !important;
  transition-property: auto !important;
}
.MuiBox-root.css-i9gxme {
  height: 22px;
}
.css-1hmoxqf-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #6876d5 !important;
}
.css-zpcf5m-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #6876d5 !important;
}
.css-9loypb-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #3648c7;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-rounded.MuiPaginationItem-textPrimary.MuiPaginationItem-previousNext.css-fhgnc3-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: #fff !important;
  border: 1px solid #9ba3e3 !important;
  color: #3648c7 !important;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: 0px 0px 5px 0px rgba(46, 45, 116, 0.1) !important;
}
@media (max-width: 425px) {
  .css-1u6l12l-MuiTypography-root {
    margin-bottom: 15px !important;
  }
}
.defaultThemeTitle {
  margin: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #333843;
  margin-top: 1%;
  margin-left: 10px;
  max-width: 72px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
}

.defaultThemeTitle:hover::after {
  content: attr(title);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.css-1fxa6kt-MuiToolbar-root {
  padding-left: unset;
  min-height: '40px';
}

.css-1pjgy12-MuiButtonBase-root-MuiTab-root {
  min-height: 30px !important;
  padding: 6px 16px !important;
}
.css-1j7mup9-MuiButtonBase-root-MuiTab-root {
  min-height: 30px !important;
  padding: 6px 16px !important;
}
.css-xa8li9-MuiTabs-root {
  min-height: 35px !important;
}
.css-1ufp7cu-MuiButtonBase-root-MuiTab-root {
  min-height: 35px !important;
}
.css-o4hahh-MuiButtonBase-root-MuiTab-root {
  min-height: 35px !important;
}
.css-19izn2v-MuiButtonBase-root-MuiTab-root {
  min-height: 35px !important;
}
.css-bmoxj4-MuiList-root-MuiMultiSectionDigitalClockSection-root :hover {
  width: fit-content !important;
}
.css-bmoxj4-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  width: fit-content !important;
}
ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root.css-1ed1o9w-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  width: 2px;
  background-color: #c1c1c1;
}
ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root.css-1ed1o9w-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullScreen.css-m9glnp-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
}
@media screen and (min-width: 1031px) and (max-width: 1430px) {
  .layout {
    width: 1060 !important;
  }
}
