.legend-container {
  width: 240px;
  position: sticky;
  float: right;
  margin-right: 2.5%;
  bottom: 25px;
  border: 2px solid #e4e4e4;
  padding: 3px 5px 5px 5px;
  border-radius: 5px;
  background-color: #fff;

  .legend-value-container {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 10px;
      color: #333843;
      font-weight: 500;
      font-family: Inter, sans-serif;
    }
  }

  .legend-gradient {
    height: 7px;
    margin-top: 1px;
  }
}
