$black: #000000;
$light-black: rgba(0, 0, 0, 0.1);
$dark-charcoal: #212330;
$midnight-black: #212431;
$soft-black: #17163A;
$shadow-light: #0000001f;
$overlay-light: rgba(0, 0, 0, 0.26);

$blackish-charcoal: #34384A;
$dark-navy: #171923;
$red: #FF0000;
$white: #ffffff;
$light-gray: #F6F6F6;
$light-grayish-blue: #BEC0C9;
$light-lavender: #EFEFFD;
$mild-white :#d8d8d8;
$dark-grey: #4d5464;
$light-grey: #858D9D;
$grayish-blue: #F9F9FC;
$dark-grayish-blue: #464656;
$soft-gray: #f0f1f3;
$grayish-silver : #535353;

$igrafx-blue: #3648c7;
$steel-blue: #51556C;
$midnight-blue: #2A2D3D;
$slate-blue: #4D526C;
$periwinkle-blue: #6876D5;
$light-purple: #9BA3E3;
$canvas-color: #FAFAFF;
$blackish-blue : #171924;
$pale-blue: #1890FF;
$cool-grayish-blue: #222332;

$cool-gray: #667085;
$charcoal-gray: #333843;
$mild-gray: #f5f6f7;
$soft-gray: #e0e2e7;
$ligth-siver: #e7e7e7;
$bluish-silver: #BEBFC8;
$pale-gray: #eeeeee;
$light-pearl: #fafafa;
$slate-gray : #D9D9D9;

$bluish-silver: #BEBFC8;

$bluish-silver: #BEBFC8;


$yellowish-orange: #cdd1f1;
$royal-blue: #041AB9;
$lavender-mist: #9BA3E3;

$light-pink: #FDF1E8;
$red: #d32f2f;
:export {
    black: $black;
    darkCharcoal: $dark-charcoal;
    midnightBlack: $midnight-black;
    darkNavy: $dark-navy;
    white: $white;
    lightGray: $light-gray;
    lightGrayishBlue: $light-grayish-blue;
    lightLavender: $light-lavender;
    igrafxBlue: $igrafx-blue;
    steelBlue: $steel-blue;
    midnightBlue: $midnight-blue;
    slateBlue: $slate-blue;
    periwinkleBlue: $periwinkle-blue;
    coolGray: $cool-gray;
    charcoalGray: $charcoal-gray;
    mildGray: $mild-gray;
    mildWhite: $mild-white;
    yellowishOrange: $yellowish-orange;
    softGray: $soft-gray;
    darkGrey: $dark-grey;
    lightGrey: $light-grey;
    grayishBlue: $grayish-blue;
    darkGrayishBlue: $dark-grayish-blue;
    royalBlue: $royal-blue;
    ligthSilver: $ligth-siver;
    blackishBlue: $blackish-blue;
    bluishSilver: $bluish-silver;
    red: $red;
    lightPink: $light-pink;
    lightPurple: $light-purple;
    blakishCharcoal: $blackish-charcoal;
    canvasColor: $canvas-color;
    softBlack: $soft-black;
    lavenderMist: $lavender-mist;
    paleGray: $pale-gray;
    lightPearl: $light-pearl;
    paleBlue: $pale-blue;
    slateGray: $slate-gray;
    coolGrayishBlue:$cool-grayish-blue;
    grayishSilver: $grayish-silver;
    lightBlack :$light-black;
    shadowLight:$shadow-light;
    overlayLight:$overlay-light;
}