.loading-spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  z-index: 1000000;
}

.dashboard-loader {
  position: absolute;
  bottom: 45%;
  left: 45%;
  z-index: 100000;
}

.widget-loader {
  position: absolute;
  bottom: 60%;
  left: 30%;
  z-index: 100000;
}

.gridView-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100000;
}

.mainSection-blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  pointer-events: none;
}
