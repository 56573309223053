.duplicate-widget-dashboard-select .MuiSelect-select {
  padding-left: 0px;
}

.duplicate-widget-dashboard-select {
  padding: 8px 10px;
  width: 100%;
  align-items: center;
  gap: 4;
  height: 40px;
  color: #4d5464 !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  border-radius: 8px;
  border: 1px solid #e0e2e7;
  background-color: #ffffff;
}

.add-edit-dashboard-dialog-title {
  color: #4d5464;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.09px;
}
