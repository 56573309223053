.MuiAppBar-root.header-container {
  background-color: #ffffff;
  padding: 0px 12px 0px 15px;
  height: 60px;
  box-shadow: 0px 4px 30px 0px rgba(131, 98, 234, 0.05);
}
.logo-image {
  width: 200px;
  height: 33px;
  margin-right: 20px;
  position: relative;
  top: -3px;
}

.header-logo {
  width: 200px;
  height: 33px;
}

.MuiButton-root.workgroups-btn {
  color: #667085;
  display: block;
  text-transform: unset;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: unset;
  position: relative;
  bottom: -4px;
  cursor: pointer;
}
.downarrow {
  color: '#667085';
}
.workgroups-section2 {
  display: flex !important;
}
.workgroups-section1 {
  display: none;
}
.language-dropdown {
  margin-right: 2%;
}
.language-dropdown-select {
  width: 100px;
  height: 30px;
  padding-bottom: 50px;
}

.MuiMenuItem-root .profile-icon {
  font-size: 20px;
  margin-right: 5px;
}

.MuiMenuItem-root.version-menu {
  background-color: transparent !important;
  font-size: 12px;
  cursor: default;
}

.MuiAvatar-root.icon-avatar {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 1068px) and (min-width: 768px) {
  .workgroups-section2 {
    display: flex !important;
  }
  .workgroups-section1 {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .workgroups-section1 {
    display: flex !important;
  }
  .workgroups-section2 {
    display: none !important;
  }
}
