.image-container {
  text-align: center;
  flex-grow: 1;
}

.image-grid {
  margin-top: 0px;
  padding: 20px 20px 40px 20px;
}

.image-main-container {
  margin: 2rem;
}