.dashboard-template-container {
  padding-right: 10px;
  height: 350px;
  overflow-y: auto;
}

.template-section {
  margin-top: 10px;
}

.template-card {
  padding: 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}

.selected-template-card {
  padding: 10px;
  border: 1px solid #3648c7;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}

.template-description {
  margin-top: 5px;
  width: 100%;
}

.template-widgets-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.template-widget {
  width: 30%;
}

.select-template-section {
  text-align: center;
  width: 100%;
  background-color: #efeffd;
  padding: 3px 5px 8px 5px;
  border-radius: 8px;
}

.template-selected-section {
  text-align: center;
  border: 1px solid #e0e2e7;
  width: 100%;
  background-color: #fff;
  padding: 3px 5px 8px 5px;
  border-radius: 8px;
}

.display-inline-block {
  display: inline-block;
}

.widget-menu-item {
  padding: 5px 15px 5px 15px;
}
