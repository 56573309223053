.settings-dialog-container .MuiDialog-paper {
  max-width: 800px;
  width: 600px;
}

.MuiGrid-item.dialog-grid-item {
  margin-top: 12px;
  margin-bottom: 12px;
}

.settings-label {
  margin-bottom: 3px;
  color: #4d5464;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.MuiGrid-item.cancel-btn-container {
  margin-top: 12px;
  padding-left: 0px !important;
}

.MuiGrid-item.apply-btn-container {
  margin-top: 12px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: right;
}

.MuiButton-root.cancel-btn {
  color: #3648c7;
  border: 1px solid #3648c7;
}

.MuiButton-root.generic-settings-btn {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 10px 14px 12px 14px;
  border-radius: 8px;
  box-shadow: unset;
}

.MuiButton-root.apply-btn {
  color: #fff;
  border-radius: 8px;
  background-color: #3648c7;

  &:hover {
    background-color: #3648c7;
    color: #fff;
    box-shadow: unset;
  }
}