.character-limit-msg {
    position: relative;
    left: 15px;
}

.text-editor {
    height: 100vh;

    .quill {
        height: 50%;
    }
}

.text-description-limit-message {
    position: absolute;
    bottom: 90px;
    font-size: 12px;
    font-style: normal;
    font-family: Inter, sans-serif;
    font-weight: 500;
    letter-spacing: 0.07px;
    color: #333843;
}