.grid-container {
  width: 100%;
  margin-left: 2px;
}

.dimensional-filter-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
