.page-header {
    display: flex;
    padding-left: 20px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.profile-header {
    margin-left: 10px;
    margin-top: 4px;
}

.profile-content-container {
    width: 450px;
    height: auto;
    margin-left: 33%;
    margin-top: 1%;
    margin-bottom: 2%;
    padding: 2%;
}

.profile-icon-container {
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.profile-icon-inner-container {
    border: 3px solid #D5D5D5;
    border-radius: 60px;
}

.profile-attributes {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
}

.profile-language-dropdown {
    margin-top: 3%;
}

.language {
    margin-top: 3%;
}

.profile-page-input-fields {
    border-radius: 8px; 
    margin-top: 3% !important;
}

.MuiAvatar-root.profile-image {
    width: 80px;
    height: 80px;
    margin: 2px;
}