$primary-font-family: Inter, sans-serif;

.dashboardHeaderContainer {
  justify-content: space-between;
  height: 60px;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}

.MuiButton-root.generic-btn {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 10px 14px 12px 14px;
  border-radius: 8px;
  box-shadow: unset;
}

.export-menu-icons {
  font-size: 20px;
  padding-right: 15px;
}

// Dashboard Header CSS Started
.responsive-dashboardHeaderContainer {
  display: none;
}

.dashboardHeader-mainGrid {
  padding: 0px 25px 0px 25px;
}

.back-arrow {
  color: #17163a;
}

.lock-icon {
  color: #667085;
  position: relative;
  left: -15px;
  top: 3px;
}

.dashboard-editableSection {
  width: 80%;
}

.MuiTextField-root.dashboardName-section {
  height: 20px;
  padding: 4px;
  color: #333843;
  font-size: 24px;
  font-weight: 700;
  margin-left: 2%;
  width: 150px;
  position: relative;
  top: -5px;
  left: -15px;

  .MuiFormHelperText-root.Mui-error {
    width: 150%;
    margin-left: -5px;
    margin-top: -2px;
  }
}

.star-icon {
  color: #667085;
  margin-left: 1%;
}

.MuiTypography-root.dashboard-title {
  color: #333843;
  font-size: 24px;
  margin-left: 20px;
  font-weight: 700;
  font-family: Inter, sans-serif;
  width: 60%;
}

.MuiButton-root.dashboard-DiscardBtn {
  position: relative;
  bottom: 5px;
  right: 20px;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 40px;
}

.MuiButton-root.dashboard-DiscardBtn:hover {
  box-shadow: unset;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
}

.MuiButton-root.dashboard-SaveBtn {
  position: relative;
  bottom: 5px;
  right: 5px;
  box-shadow: unset;
  background-color: #3648c7;
  border-radius: 8px;
  text-transform: unset;
  height: 40px;
}

.MuiButton-root.dashboard-SaveBtn:hover {
  box-shadow: unset;
  background-color: #3648c7;
}

.MuiButton-root.dashboard-ShareBtn {
  position: relative;
  bottom: 5px;
  right: 25px;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 40px;
  min-width: max-content;
}

.MuiButton-root.global-filter-btn {
  position: relative;
  bottom: 5px;
  right: 40px;
  color: #667085;
  border: 0px;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 40px;
  min-width: max-content;
}

.MuiButton-root.global-filter-btn:hover {
  box-shadow: unset;
  color: #667085;
  border: 0px;
  background-color: #ffffff;
}

.MuiSvgIcon-root.global-filter-dashboard-icon {
  color: #3648c7;
}

.filter-list-badge .MuiBadge-badge {
  background-color: #3648c7;
  color: #fff;
  margin-top: -5px;
}

.MuiButton-root.dashboard-sort-btn {
  position: relative;
  bottom: 5px;
  right: 16px;
  color: #667085;
  border: 1px solid #e0e2e7;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 40px;
  min-width: max-content;
}

.MuiButton-root.dashboard-ShareBtn:hover {
  box-shadow: unset;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
}

.MuiButton-root.dashboard-ShareBtnDownload {
  position: relative;
  bottom: 3px;
  right: 30px;
  padding: 10px 14px 10px 14px;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 40px;
  min-width: max-content;
  margin-right: 10%;
}

.MuiButton-root.dashboard-ShareBtnDownload:hover {
  box-shadow: unset;
}

.MuiButton-root.dashboard-Btn {
  position: relative;
  bottom: 5px;
  right: 10px;
  box-shadow: unset;
  background-color: #3648c7;
  border-radius: 8px;
  text-transform: unset;
  height: 40px;
  min-width: max-content;
}

.MuiButton-root.dashboard-Btn:hover {
  box-shadow: unset;
  background-color: #3648c7;
}

.MuiButton-root.newDashboard-Btn {
  position: relative;
  bottom: 5px;
  right: 40px;
  box-shadow: unset;
  background-color: #3648c7;
  border-radius: 8px;
  text-transform: unset;
  height: 40px;
  min-width: max-content;
}

.MuiButton-root.newDashboard-Btn:hover {
  box-shadow: unset;
  background-color: #3648c7;
}

.MuiSvgIcon-root.add-dashboard-icon {
  color: #3648c7;
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 0;
}

.disabled-add-dashboard-btn {
  padding-left: 20px;
  padding-top: 5px;
  pointer-events: none;
  color: #fff;
  color: #808080cc;
}

.MuiSvgIcon-root.edit-dashboard-icon {
  height: 0.8rem;
  width: 0.8rem;
  color: #3648c7;
  padding-right: 10px;
}

.leftSectionDropDown-select .MuiSelect-select {
  padding-left: 0px;
}

.leftSectionDropDown-select {
  padding: 8px 10px;
  width: 250px;
  align-items: center;
  gap: 4;
  height: 32px;
  color: #4d5464 !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  border-radius: 8px;
  border: 1px solid #e0e2e7;
  background-color: #ffffff;
}

.dashoboard-select-container {
  display: flex;
  align-items: center;
}

.dashboard-MainContainer {
  display: block;
  width: 100%;
  background-color: #fff;
  overflow-x: hidden;
}

.charts-outerContainer-1 {
  display: inline-block;
  width: calc(100% - 350px);
}

.charts-outerContainer-2 {
  display: inline-block;
  width: calc(100% - 5px);
}

.display-components {
  display: inline-block;
  background-color: #ffffff;
}

.MuiButton-root.more-vert-icon {
  padding: 5px;
  margin-left: 5px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  min-width: auto;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
}

.margin-top {
  margin-top: 5px;
}

// Middle Section CSS Started

.middleSection-container {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.icon-container {
  width: 100%;
  height: 40px;
  text-align: center;
  margin-bottom: 15px;
  padding-left: 5px;
}

// Widget Section CSS started
.right-sectionContainer {
  padding: 1.2rem;
  background-color: #ffffff;
}

.tabsContainerBox {
  position: sticky;
  top: 0;
  margin-bottom: 20px;
}

.tabsContainer {
  margin-top: 10px;
}

.widgetContainer {
  justify-content: space-between;
}

.draggableBox {
  text-align: center;
  width: 210px;
  height: 39px;
  cursor: move;
  background-color: #efeffd;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 6px;
}

.cursor-pointer {
  cursor: pointer;
}

.chartsDetails {
  font-size: 16px;
  line-height: 24px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
}

.charts {
  color: #808b99;
}

.existing-charts {
  margin-left: 10%;
  color: #808b99;
}

// Left Section CSS Started
.left-section {
  background-color: #f9f9fc;
  padding: 15px 5px 15px 5px;
  width: 100%;
}

.left-sectionScrolling {
  background-color: #f9f9fc;
  padding: 15px 12px 15px 5px;
  width: 100%;
  height: calc(100vh - 155px);
  overflow-y: auto;
}

.backgroundGrid {
  display: grid;
  grid-template-columns: repeat(12, 68px);
  gap: 10px;
  width: 921px;
  height: 200vh;
  position: absolute;
  left: 0;
  pointer-events: none;
  margin-left: 25px;
  margin-top: 12px;
}

.grid-wrapper {
  display: block;
  position: relative;
}

.grid-responsiveWrapper {
  display: none;
}

.grid-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.grid-item:hover {
  border: 1px dotted #87cefa;
}

.grid-itemContent {
  position: relative;
  cursor: move;
}

.main-responsiveContainer {
  padding: 20px 20px 20px 25px;
}

.dropDown-select {
  width: 200px;
  height: 30px;
  margin-bottom: 10px;
  margin-left: 0.6rem;
}

// Add Edit Widget CSS Started
.addEditWdiget-container {
  .MuiFormLabel-asterisk {
    color: red !important;
  }
}

.MuiButton-root.addEdit-DiscardBtn {
  margin-right: 15px;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 40px;
}

.MuiButton-root.addEdit-DiscardBtn:hover {
  box-shadow: unset;
}

.MuiGrid-item.addEdit-newChartGrid {
  display: flex;
  align-items: center;
  padding: 15px 0px 10px 30px !important;
  border-bottom: 1px solid #e7e7e7;
}

.MuiButton-root.addEdit-SaveBtn {
  margin-top: -1px;
  box-shadow: unset;
  background-color: #3648c7;
  border-radius: 8px;
  text-transform: unset;
  height: 40px;
}

.MuiButton-root.addEdit-SaveBtn:hover {
  box-shadow: unset;
  background-color: #3648c7;
}

.chartProperties-grid {
  height: calc(100vh - 90px);
  overflow-y: auto;
  padding: 10px 20px 0px 10px;
}

.red-asterisk {
  color: red;
  font-size: 15px;
}

.MuiOutlinedInput-root.add-description {
  height: 100px;
  width: 90%;
}

.MuiOutlinedInput-root.add-textTitle {
  width: 90%;
}

.character-section {
  position: relative;
  top: -10px;
  left: 20px;
  font-size: 12px;
  font-style: normal;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: #333843;
}

// Theme Section CSS Started

.themeheader-Container {
  display: flex;
  padding-left: 1.2rem;
  padding-top: 1rem;
}

.themeTitle-section {
  width: 60%;
}

.MuiTypography-root.themeTitle {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: #333843;
  line-height: 24px;
  letter-spacing: 0.07px;
}

.MuiButton-root.createTheme-btn {
  padding: unset;
  text-transform: unset;
  color: #3648c7;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.themeCards-section {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding-left: 20px;
}

.themeCards {
  max-width: 230px;
  width: 220px;
  height: 90px;
  margin-bottom: 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.themeCardsModal {
  display: flex;
  justify-content: space-between;
}

.defaultThemeTitle {
  margin: unset;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #333843;
  margin-top: 1%;
  margin-left: 7px;
}

.theme-title {
  padding: 6px 8px;
}

.MuiButton-root.theme-btn {
  min-width: 30px;
  width: 35px;
}

.defaultThemeFont {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 3%;
  margin-bottom: 2%;
}

.delete-confirmation .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.04);
}

.delete-confirmation .MuiDialog-container .MuiDialog-paper {
  box-shadow: none;
  max-width: 45%;
  border-radius: 6px;
}

.delete-confirmation .MuiDialogContent-root {
  padding-left: 2rem;
  padding-right: 2rem;
}

.delete-note {
    border-radius: 4px;
    padding: 7px 10px;
    margin-top: 20px;
}

.p.pmv-container text {
  font-family: $primary-font-family;
}

// Create Theme Dialog CSS Started
.add-edit-dialog .MuiDialogActions-root {
  padding-left: 20px;
}

.themeMain-container {
  height: calc(100vh - 123px);
  overflow-y: auto;
  border-left: 1px solid #d8d8d8;
}

.modalThemeName {
  margin-bottom: 5px;
}

.modalThemeDescription {
  margin-top: 20px;
}

.colorNotSelectedError {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 0rem;
}

.shadeColorHexCode {
  width: 83px;
  margin-left: 10px;
}

.modalShadeColor {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e0e2e7;
  padding: 8px 6px;
  background-color: #f9f9fc;
  border-radius: 3px;
}

.actionButtons {
  margin-left: 100px;
  display: flex;
  width: '40%';
}

.MuiDialogActions-root.createTheme-bottomSection {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 20px 30px;
}

.MuiButton-root.createTheme-cancelBtn {
  text-transform: unset;
  color: #3648c7;
  border: 1px solid #3648c7;
}

.MuiButton-root.createTheme-cancelBtn:hover {
  box-shadow: unset;
}

.MuiButton-root.createTheme-saveBtn {
  text-transform: unset;
  background-color: #3648c7;
  color: #ffffff;
}

.MuiButton-root.createTheme-saveBtn:hover {
  box-shadow: unset;
}

.padding-top-20 {
  padding-top: 20px !important;
}

@media only screen and (max-width: 1068px) and (min-width: 768px) {
  .display-components {
    display: none;
  }

  .grid-wrapper {
    display: none;
  }

  .grid-responsiveWrapper {
    display: block;
    width: 100%;
  }

  .main-responsiveContainer {
    padding: 20px 5px 20px 25px;
  }

  .grid-itemContainer {
    padding-right: 20px;
  }

  .select-section {
    display: none;
  }

  .dashboardHeaderContainer {
    display: none;
  }

  .responsive-dashboardHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #fff;
    width: 100%;
  }

  .MuiTypography-root.dashboard-title {
    font-size: 20px;
    width: 78%;
    padding-left: 10px;
  }

  .MuiButton-root.dashboard-ShareBtn {
    width: 30px;
    margin-right: 20px;
    height: 30px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .display-components {
    display: none;
  }

  .grid-wrapper {
    display: none;
  }

  .grid-responsiveWrapper {
    display: block;
    width: 100%;
  }

  .select-section {
    display: none;
  }

  .dashboardHeaderContainer {
    display: none;
  }

  .responsive-dashboardHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #fff;
    width: 100%;
  }

  .MuiTypography-root.dashboard-title {
    font-size: 18px;
    width: 78%;
    padding-left: 8px;
  }

  .MuiButton-root.dashboard-ShareBtn {
    width: 30px;
    margin-right: 20px;
    height: 30px;
    margin-top: 0px;
  }
}