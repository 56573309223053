@mixin text-overflow($max-width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

.text-overflow-95 {
  @include text-overflow(95%);
}

.text-overflow-25 {
  @include text-overflow(25%);
}

.text-overflow-34 {
  @include text-overflow(34%);
}

.text-overflow-15rem {
  @include text-overflow(15rem);
}

.text-overflow-60 {
  @include text-overflow(60%);
}

.text-overflow-80 {
  @include text-overflow(80%);
}

.text-overflow-100 {
  @include text-overflow(100%);
}
