.MuiTypography-root.filter-lists {
  position: relative;
  bottom: -5px;
}

.load-filters-grid {
  text-align: right;
}

.MuiTypography-root.load-filters-lists {
  display: inline-block;
}

.MuiSvgIcon-root.filter-icon {
  height: 20px;
  margin-right: 3px;
}

.load-filter-menu .MuiMenu-paper {
  max-height: 300px;
  height: auto;
  min-width: 150px;
  overflow-y: auto;
}

.no-filter-menu .MuiMenu-paper {
  max-height: 300px;
  height: 100px;
  min-width: 150px;
  overflow-y: auto;
}

.add-filter-container {
  border: 1px solid #e0e2e7;
  height: 40px;
  border-radius: 8px;
}

.filter-inner-section {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.add-filter {
  margin-top: 12px;
  margin-left: 15px;
}

.add-filter-icon {
  width: 2%;
  height: 2%;
  padding: unset;
  font-size: 1.5rem;
  line-height: unset;
  margin-top: 8px;
  margin-right: 10px;
}

.added-filters {
  padding-left: 10px;
  height: 40px;
  margin-bottom: 3%;
  border-radius: 8px;
  border: 1px solid #e0e2e7;
}

.no-filter-found {
  margin: 25px 15px 25px 15px;
}

.filters {
 line-height: 32px;
}