.edit-template-container {
  position: relative;
  bottom: 30px;
  margin-top: 18px;
}

.initial-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.non-editable-chart-image {
  width: 150px;
}

.non-editable-chart-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  // fontFamily: 'Inter',
}

.info-icon {
  margin-right: 8px;
  color: #bbbbbb;
}

.chart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 600;
  margin-top: -10px;
}

.add-datapoints {
  color: #464f59;
  font-size: 16px;
  font-weight: 600;
}
