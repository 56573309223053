.widget-card {
  height: 100%;

  #chart-container {
    height: inherit;
  }
}

.widget-card-title {
  padding-left: 15px;
  padding-top: 10px;
}

.widget-action-row {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;

  .action-buttons {
    display: flex;
    flex-direction: row;
  }
}

.widget-icons-container {
  display: flex;
}