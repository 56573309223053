.metric-widget-mode {
  margin-top: 20px;
}

.metric-widget-data {
  margin: auto;
  margin-top: 20px;
  text-align: center;
  color: #3648c7;
}

.metric-widget-description {
  margin-top: 15px;
  text-align: center;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #333843;
  padding: 0px 5px 0px 25px;
}

.metric-view-mode {
  margin-top: 15px;
  height: 100%;
  padding-right: 10px;
}

.metric-view-mode-data {
  text-align: center;
  color: #333843;
}

.metric-view-mode-description {
  margin-top: 20px;
  text-align: center;
  color: #667085;
  height: calc(100% - 140px);
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden auto;
  padding: 0px 15px 10px 15px;
}