@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&family=Open+Sans:wght@300;400;500;700&display=swap');
@import url('../src/styles/Fonts.scss');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Inter, Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9fc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: content-box;
}
