.tabContent {
  margin: 2%;
}

.selectedTab {
  background-color: #cdd1f1 !important;
  color: #4543ae !important;
  font-weight: 600 !important;
}

.dashboardTabsContainer {
  margin-top: 10px;
  margin-bottom: 20px;
}

.dashboard-action-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 6px;
  padding-left: 7px;

  .dashboard-action-buttons {
    display: flex;
    flex-direction: row;
  }
}

.margin-top-20 {
  margin-top: 20px;
}

.dashboard-action-row-not-selected {
  border: 1px solid #e0e2e7;
  background-color: white;
}

.dashboard-action-row-selected {
  border: 1px solid #9ba3e3;
  background-color: #f8f8ff;
}

.MuiSvgIcon-root.favourite-icon {
  color: #efa670;
}

.add-dashboard-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  background-color: #efeffd;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 6px;
}

.no-dashboard-found {
  color: #4d5464;
  margin: 25px 15px 25px 15px;
}

.MuiDialogContent-root.dialog-main-section {
  padding: 1% 6% 1% 7%;
}