.spinner-container {
  position: absolute;
  top: 0%;
  left: 0%;
  display: block;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}