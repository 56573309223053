@import '../../../styles/constants.scss';

.cool-gray-color {
  color: $cool-gray !important;
}

.charcoal-gray-color {
  color: $charcoal-gray !important;
}

.disply-block {
  display: block;
}

.mb-10 {
  margin-bottom: 10px;
}

.MuiChip-root {
  max-width: 190px !important;
  margin-bottom: 5px !important;
  margin-left: unset !important;
  margin-right: 8px !important;
}

.MuiStack-root {
  flex-wrap: wrap !important;

}

.MuiPopover-paper {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06) !important;
}