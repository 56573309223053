.image-panel-container {
  height: calc(100% - 75px);
  padding: 0px 20px 0px 20px;
  margin-top: 5px;
}

.image-panel {
  width: 100%;
  height: 100%;
}

.image-panel-view-mode {
  width: 100%;
  height: 400px;
}
