.report-container {
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  color: #333843;
  line-height: 24px;
  letter-spacing: 0.07px;
  text-align: center;
  min-height: 24px;
}

.report-preview-container {
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.widget-placeholder {
  display: flex;
  justify-content: center;
}