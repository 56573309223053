.MuiButton-root.existing-charts-button {
  padding-right: 0px;
  padding-left: 0px;
  font-size: 14px;
  margin-right: 10px;
  color: #667085;
  text-transform: unset;
  font-family: Inter, sans-serif;
  cursor: pointer;
}

.components-tab-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  margin-bottom: 20px;
}

.components-tab-existing-chart-container {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  margin-bottom: 20px;
}

.back-arrow {
  cursor: pointer;
  position: relative;
  top: 2px;
}

.existingChartsContainerBox {
  position: sticky;
  top: 0;
  margin-bottom: 20px;
}

.existing-chart-row {
  border-radius: 6px;
  max-width: 230px;
  width: 220px;
  height: 90px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.existing-chart-row-not-selected {
  border: 1px solid #e0e2e7;
  background-color: white;
}

.container {
  display: flex;
  flex-direction: row;
}

.widget-image-placeholder-section {
  width: 30%;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-image-placeholder {
  width: 100%;
  height: 100%;
}

.right-section {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.row {
  flex-grow: 1;
}

.add-existing-chart-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeffd;
  cursor: pointer;
  padding-right: 4px;
  border-radius: 6px;
  font-size: 14px;
  font-family: Inter, sans-serif;
  color: #000000;
  width: 65px;
  border: 1px solid #9ba3e3;
}

.disable-add-existing-chart-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeffd;
  padding-right: 4px;
  border-radius: 6px;
  font-size: 14px;
  font-family: Inter, sans-serif;
  color: #000000;
  width: 65px;
  border: 1px solid #9ba3e3;
  opacity: 0.5;
  cursor: not-allowed;
}

.widget-name-truncate {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #6876d5;
  font-weight: 700;
  font-family: Inter, sans-serif;
  letter-spacing: 0.5px;
  position: relative;
  top: 10px;
  left: 3px;
  width: 80%;
}

.data-source-details {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.data-source-name-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter, sans-serif;
  letter-spacing: 0.5px;
  width: 60%;
}

.data-source-placeholder {
  margin-right: 5px;
  padding-top: 2px;
  display: flex;
  align-items: center;
}

.right-section-container {
  padding: 10px 15px 10px 15px;
  background-color: #ffffff;
}

.widget-component-button {
  display: flex;
  align-items: center;
  width: 215px;
  height: 39px;
  background-color: #efeffd;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 6px;

  .name-icon {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.widget-component-generic-button {
  display: inline-block;
  margin-left: 5px;
}

.report-add-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
