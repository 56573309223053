.MuiButton-root.btn {
  border-radius: 8px;
  text-transform: unset;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: unset;
  min-width: max-content;
  font-weight: 600;
  font-family: Inter, sans-serif;
  font-size: 14px;
  letter-spacing: 0.07px;

  &.primary-btn {
    background-color: #3648c7;
  }

  &.secondary-btn {
    color: #3648c7;
    border: 1px solid #3648c7;
    background-color: transparent;
  }
}

.Mui-disabled {
  background-color: #0000001f !important;
  border: 0px !important;
  color: rgba(0, 0, 0, 0.26) !important;
}