@import './constants.scss';
@import './baseStyles.scss';

@mixin base-title($font-size, $font-weight) {
  font-family: Inter, sans-serif;
  letter-spacing: 0.07px;
  color: $charcoal-gray;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin template-title($font-size, $font-weight) {
  font-family: Inter, sans-serif;
  letter-spacing: 0.07px;
  color: $periwinkle-blue;
  font-size: $font-size;
  font-weight: $font-weight;
}

.title-12-400 {
  @include base-title(12px, 400);
}

.title-12-500 {
  @include base-title(12px, 500);
}

.title-14-400 {
  @include base-title(14px, 400);
}

.title-14-500 {
  @include base-title(14px, 500);
}

.title-14-600 {
  @include base-title(14px, 600);
}

.title-14-700 {
  @include base-title(14px, 700);
}

.title-15-700 {
  @include base-title(15px, 700);
}

.title-16-400 {
  @include base-title(16px, 400);
}

.title-16-500 {
  @include base-title(16px, 500);
}

.title-16-600 {
  @include base-title(16px, 600);
}

.title-16-700 {
  @include base-title(16px, 700);
}

.title-20-400 {
  @include base-title(20px, 400);
}

.title-20-700 {
  @include base-title(20px, 700);
}

.title-18-500 {
  @include base-title(18px, 500);
}

.title-18-700 {
  @include base-title(18px, 700);
}

.title-24-400 {
  @include base-title(24px, 400);
}

.title-24-500 {
  @include base-title(24px, 500);
}

.title-24-700 {
  @include base-title(24px, 700);
}

.title-34-700 {
  @include base-title(34px, 700);
}

.template-title-14-400 {
  @include template-title(14px, 400);
}
