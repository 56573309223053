.MuiButton-root.genericFilter-btn {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 10px 14px 12px 14px;
  border-radius: 8px;
}
.genericfilter-grid {
  padding-left: 0px !important;
}
.MuiButton-root.validate-btn {
  font-size: 14px;
  color: #fff;
  background-color: #3648c7;
  position: relative;
  top: 15px;
  right: 25px;
  &:hover {
    background-color: #2a3a9f;
    color: #fff;
    box-shadow: unset;
  }
}
.MuiButton-root.disabledSaveValidate-btn {
  pointer-events: none;
  color: #fff !important;
  background-color: #808080cc;
  position: relative;
  top: 15px;
  right: 25px;
}
.MuiButton-root.filterCancel-btn {
  font-family: Inter, sans-serif;
  color: #3648c7;
  border: 1px solid #3648c7;
  position: relative;
  top: 15px;
}

// Add Edit Filter CSS Started

.custom-periodSection {
  width: 100%;
}

.custom-period {
  display: inline-block;
  width: 27%;

}

.custom-periodCompare {
  display: flex;
  flex-direction: column;
}

.paddingRight20 {
  padding-right: 20px;
}

.select-filter-type {
  margin-top: 10px;
  margin-bottom: 10px;
}

.process-scope {
  margin-top: 2%;
}

.time-scale-select {
  margin-top: 8px;
  margin-bottom: 10px;
}