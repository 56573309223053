.app-list-view {
  background-color: #f9f9fc;

  .list-card {
    height: 80px;
    margin: 10px 20px;
    border-radius: 8px;
    border: 1px solid #F0F1F3;
    background-color: #ffffff;
  }

  .list-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .favourite-button {
    display: flex;
    flex-direction: row;
  }

  .app-name {
    cursor: pointer;
  }

  .truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}