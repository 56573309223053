.global-filter-main-section {
  padding: 10px;
  height: calc(100vh - 90px);
  overflow: hidden auto;
}

.back-arrow {
  cursor: pointer;
  position: relative;
  top: 5px !important;
}

.add-new-filter {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-filter-form-section {
  margin-top: 25px;
}

.filter-name-duplicate {
  margin-left: 10px;
}

.filter-data-source {
  margin-top: 35px;
}

.saved-filters {
  margin-top: 30px;
}

.filter-list-error-msg {
  margin-left: 20px;
  margin-top: 5px;
}

.global-filter-section {
  display: flex;
  justify-content: space-between;
}

.global-filter-btn-section {
  padding: 10px;
  margin-top: 10px;
}

.MuiButton-root.generic-global-filter-btn {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 10px 14px 12px 14px;
  border-radius: 8px;
  box-shadow: unset;
}

.MuiButton-root.apply-all-btn {
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 35px;
}

.MuiButton-root.apply-all-btn:hover {
  box-shadow: unset;
  color: #3648c7;
  border: 1px solid #3648c7;
  background-color: #ffffff;
}

.MuiButton-root.filter-apply-btn {
  color: #ffffff;
  background-color: #3648c7;
  box-shadow: unset;
  text-transform: unset;
  border-radius: 8px;
  height: 35px;
}

.MuiButton-root.filter-apply-btn:hover {
  box-shadow: unset;
  color: #ffffff;
  background-color: #3648c7;
}

.MuiFormLabel-asterisk {
  color: red !important;
}
