@import '../../styles/constants.scss';

.chart-title {
    display: flex;
    justify-content: center;
}

.chart-configuration-title-color {
    color: $cool-gray,
}

.chart-title-color {
    color: $charcoal-gray;
}