.textChart-addEditDescription {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-family: Inter, sans-serif;
  font-weight: 500;
  letter-spacing: 0.07px;
  height: 100%;
  line-height: 30px;
  padding-left: 25px;
}

.textChart-leftSectionDescription {
  margin-top: 15px;
  color: #667085;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  height: 100%;
  padding-right: 5px;
}

.textChart-description {
  text-align: justify;
  height: calc(100% - 80px);
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: auto auto;
  padding: 0px 15px 0px 15px;
}

.textChart-description2 {
  width: 100%;
  text-align: left;
  height: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

@media only screen and (max-width: 1068px) and (min-width: 768px) {
  .leftSection-dataSection {
    position: relative;
    top: -45px;
    font-size: 24px;
  }

  .leftSection-descriptionSection {
    position: relative;
    top: -55px;
  }
}

@media only screen and (max-width: 767px) {
  .leftSection-dataSection {
    position: relative;
    top: -40px;
  }

  .leftSection-descriptionSection {
    position: relative;
    top: -50px;
  }
}