@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import './constants.scss';

.workgroup-container {
  display: flex;
  flex-direction: column;
  background-color: inherit;
}

.workgroup-mainSection {
  width: 100% !important;
  background-color: $mild-gray;
  height: 50px;
  position: fixed;
  bottom: 0px !important;
}

.MuiGrid-item.genericWorkgroup-gridItem {
  padding-left: 5px !important;
  padding-right: 15px !important;
}

.workgroup-pagination {
  width: 100% !important;
  background-color: #f5f6f7;
  height: 40px;
  position: fixed;
  bottom: 0px !important;
  display: flex;
  align-items: center;
}

.MuiTypography-root.pagination-text {
  margin-left: 20px;
  width: 275px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Inter, sans-serif;
  letter-spacing: 0.07px;
}

.workgroup-page-size-selection {
  margin-left: 20px;
  color: $cool-gray;
}

.workgroup-Header {
  flex-grow: 3;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: $mild-gray;
  border-bottom: 1px solid $mild-white;
}

.widgetContainerHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-section {
  display: inline-block;
  width: 250px;

  .MuiOutlinedInput-root {
    margin-top: 15px;
    height: 40px;
  }
}

.all-workgroups {
  width: 75%;
  padding-left: 15px;
}

.MuiTextField-root.textField-search {
  width: 90%;
  margin-left: 15px;
  border-color: #e0e2e7;
  margin-top: -5px;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.workgroup-GridContainer {
  justify-content: center;
  padding: 15px 0px 15px 25px;
  min-height: 30rem;
}

.workgroupGrid-view {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
}

.workgroups-row {
  display: flex;
  flex-direction: row;
}

.workgroup-cards {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 112px;
  margin: 8px 0px 8px 8px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e0e2e7;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.workgroup-cards:hover {
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.1);
}

.workgroup-cardsHeader {
  width: 100%;
  display: flex;
  align-items: center;
}

.workgroup-cardIconSection {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 4px solid $light-lavender;
  background-color: $yellowish-orange;
  flex-shrink: 0; 
  margin-right: 10px;
}

.card-icon {
  width: 24px;
  height: 24px;
  color: #3648c7;
  position: relative;
  top: 6px;
  left: 7px;
}

.workgroup-title {
  display: flex;
  align-items: center;
}

.section-divider {
  margin-left: 10px;
  margin-right: 10px;
  height: 1px;
  background-color: $soft-gray;
  border: none;
}

.workgroup-cardBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 1068px) and (min-width: 768px) {
  .workgroup-GridContainer {
    padding: 15px 0px 15px 28px;
  }

  .all-workgroups {
    width: 50%;
    padding-left: 25px;
  }

  .search-section {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .workgroup-GridContainer {
    padding: 15px 0px 15px 28px;
  }

  .search-section {
    width: 70%;
    padding-left: 0px;
    padding-right: 15px;
  }

  .workgroup-cards {
    margin: -2px 0px 8px 8px;
  }
}