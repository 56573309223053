.card {
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-screen-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-screen-image {
  width: 200px;
  height: 133px
}

.empty-screen {
   text-align: center;
  padding-bottom: 17px;
  padding-top: 37px;
  margin: unset;
}

.add-components-to-screen {
  display: flex;
  justify-content: center;
  margin: unset;
}