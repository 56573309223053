.MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root.filterDialog-mainSection {
  padding: 3% 3% 3% 7%;
}
/* top right bottom left */
.newApp-title {
  color: #4d5464;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.09px;
}
.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root.label-name {
  color: #4d5464;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-bottom: 5px;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item.grid-section {
  margin-top: 15px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.description-input {
  height: 60px;
  background-color: #f9f9fc !important;
}
.cancel-btn-section {
  width: 49%;
  text-align: left;
  display: inline-block;
}
.save-btn-section {
  width: 49%;
  text-align: right;
  display: inline-block;
}
.grid-btn {
  width: 100%;
}
.css-zv97gt-MuiButtonBase-root-MuiButton-root.save-btn {
  font-size: 14px;
  color: #fff;
  padding: 10px 14px 10px 14px;
  border-radius: 10px;
  background-color: #3648c7;
  margin-right: 25px;
}
.css-zv97gt-MuiButtonBase-root-MuiButton-root.cancel-btn {
  font-size: 14px;
  padding: 10px 14px 10px 14px;
  border-radius: 10px;
  color: #3648c7;
  border: 1px solid #3648c7;
  margin-left: 20px;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.public-private-btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5%;
  color: #667085;
}
.css-2qp49j-MuiPaper-root-MuiDialog-paper {
  color: #4d5464;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-bottom: 5px;
}

.p-multiselect-panel,
.p-dropdown-panel {
  z-index: 1500 !important;
}
